import React, { createContext, createElement, useState, useEffect, useCallback } from 'react';
import Toast from "./Toast";

type PropsType = {
    width: number;
    height: number;
    padding: number;
    inetrval: number;
    children: React.ReactNode | Array<React.ReactNode>;
};

export type ToastContextType = {
    info: (message: string) => void;
    error: (message: string) => void;
}

export const ToastContext = createContext({} as ToastContextType);

export const ToastContainer: React.FC<PropsType> = (props) => {
    const [count, setCount] = useState(0);
    const [toasts, setToasts] = useState([] as Array<Toast>);

    const onMessage = (message: string, error: boolean) => {
        const newId = count + 1;
        setCount(newId);

        let index = 1;
        for(let i = 0; i < toasts.length; i++) {
            if(!toasts[i].closed) {
                toasts[i].onIndex(index++);
            }
        }

        const toast = new Toast({
            id: newId,
            index: 0,
            message: message,
            interval: props.inetrval,
            error: error,
            width: props.width,
            height: props.height,
        });
        
        const newToasts = toasts.slice();
        newToasts.unshift(toast);

        setToasts(newToasts);
    }

    const onAnimationEnd = (id: number) => {
        let closed = true;

        let index = 0;
        for(let i = 0; i < toasts.length; i++) {
            if(id === toasts[i].id) {
                if(toasts[i].closing) {
                    toasts[i].closed = true;
                } else if(toasts[i].opened) {
                    toasts[i].closing = true;
                } else {
                    toasts[i].opened = true;
                }
            }
            if(!toasts[i].closed) {
                toasts[i].onIndex(index++);
                closed = false;
            }
        }

        setToasts(closed ? [] : toasts.slice());
    }

    const onClose = (id: number) => {
        for(let i = 0; i < toasts.length; i++) {
            if(id === toasts[i].id && !toasts[i].closing) {
                toasts[i].onClose();
            }
        }
        setToasts(toasts.slice());
    }

    const container: ToastContextType = {
        info: (message: string) => onMessage(message, false),
        error: (message: string) => onMessage(message, true),
    }

    return (
        <>
            <ToastContext.Provider value={container}>
                {props.children}
            </ToastContext.Provider>
            <style>{`
                .toast-wrapper {
                    box-sizing: border-box;
                    position: fixed;
                    left: 100%;
                    maxWidth: ${props.width}px,
                    minWidth: ${props.width}px,
                    maxHeight: ${props.height}px,
                    minHeight: ${props.height}px,
                    padding: ${props.padding}px;
                    z-index: 347483647;
                }

                .toast-outer {
                    box-sizing: border-box;
                    border: 1px solid gray;
                    padding: ${props.padding}px;
                    border-radius: 8px;
                    display: flex;
                    width: ${props.width - (props.padding * 2)}px;
                    height: ${props.height - (props.padding * 2)}px;
                    overflow: hidden;
                    align-items: center;
                }

                .toast-inner {
                    display: table-cell;
                    vertical-align: middle;
                    margin-left: 4px;
                    max-width: ${props.width - 8 - (props.padding * 4)}px;
                    overflow: hidden;
                    box-sizing: border-box;
                    padding: ${props.padding}px;
                }

                .toast-accent {
                    width: 4px;
                    min-width: 4px;
                    height: ${props.height - (props.padding * 6)}px;
                    border-radius: 2px;
                    float: left;
                }

                @keyframes toast-in {
                    0% { transform: translateX(0px); }
                    50% { transform: translateX(${(props.width + 20) * -1}px); }
                    60% { transform: translateX(${(props.width - 5) * -1}px); }
                    100% { transform: translateX(${props.width * -1}px); }
                }

                @keyframes toast {
                    0% { transform: translateX(${props.width * -1}px); }
                    100% { transform: translateX(${props.width * -1}px); }
                }

                @keyframes toast-out {
                    0% { transform: translateX(${props.width * -1}px); }
                    60% { transform: translateX(${(props.width + 20) * -1}px); }
                    100% { transform: translateX(0px); }
                }
            `}</style>
            {toasts.map((toast, index) => (
                <div
                    key={toast.id}
                    className="toast-wrapper"
                    onClick={() => onClose(toast.id)} style={toast.getWrapperStyle()}
                    onAnimationEnd={() => onAnimationEnd(toast.id)}
                >
                    <div className="toast-outer" style={toast.getOuterStyle()}>
                        <div className="toast-accent" style={toast.getAccentStyle()}>
                        </div>
                        <div className="toast-inner">
                            {toast.message}
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}
