import React from 'react';
import { FormType } from "modules/form";
import "css/form.css";

type PropsType = {
    label: string;
    form: FormType;
};

export const Submit: React.FC<PropsType> = (props) => {
    const { form } = props;
    return (
        <div className="submit-wrapper">
            {form.disabled ?
                <button className="disabled-submit-button" disabled>{props.label}</button>
                :
                <button className="enabled-submit-button" onClick={form.onSubmit}>{props.label}</button>
            }
        </div>
    );
}
