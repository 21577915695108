type ValidatorType = (value: any) => string | null;

const maxLength = (value: string, maxLength: number) => {
    return value.length <= maxLength ? null : `${maxLength}文字までです。`;
}

const fixedLength = (value: string, fixedLength: number) => {
    return value.length === fixedLength ? null : "" + fixedLength + "文字で入力してください。";
}

const email = (value: string) => {
    return value.match(/.+@.+\..+/) ? null : "形式が正しくありません。";
}

const date = (value: string) => {
    let message = value.match(/^\d{4}-\d{2}-\d{2}$/) ? null : "形式が正しくありません。";
    if(message === null && value !== "") {
        let date = new Date(value);
        let dateStr = `${date.getFullYear()}-${('00' + (date.getMonth()+1)).slice(-2)}-${('00' + date.getDate()).slice(-2)}`;
        console.log(dateStr);
        if(dateStr !== value) {
            message="無効な日付です。";
        }
    }
    return message;
}

const hiragana = (value: string) => {
    return value.match(/^[ぁ-んー　]*$/) ? null : "ひらがなのみで入力してください。";   
}

const numeric = (value: string) => {
    return value.match(/^[0-9]*$/) ? null : "数字のみで入力してください。";   
}

const maxLengthOfName: number = 64;
const maxLengthOfAddress: number = 128;
const maxLengthOfContents: number = 4000;

const validators: {[key: string]: Array<ValidatorType>} = {
    lastName: [
        value => maxLength(value, maxLengthOfName),
    ],
    firstName: [
        value => maxLength(value, maxLengthOfName),
    ],
    lastNameKana: [
        value => hiragana(value),
        value => maxLength(value, maxLengthOfName),
    ],
    firstNameKana: [
        value => hiragana(value),
        value => maxLength(value, maxLengthOfName),
    ],
    email: [
        value => email(value),
    ],
    tel1: [
        value => numeric(value),
        value => maxLength(value, 11),
    ],
    zipCd: [
        value => numeric(value),
        value => fixedLength(value, 7),
    ],
    address0: [
        value => maxLength(value, maxLengthOfAddress),
    ],
    address1: [
        value => maxLength(value, maxLengthOfAddress),
    ],
    address2: [
        value => maxLength(value, maxLengthOfAddress),
    ],
    companyName: [
        value => maxLength(value, maxLengthOfName),
    ],
    divisionName: [
        value => maxLength(value, maxLengthOfName),
    ],
    contents: [
        value => maxLength(value, maxLengthOfContents),
    ],
    birthDate: [
        value => date(value),
    ],
    message: [
        value => maxLength(value, maxLengthOfContents),
    ],
};

export default validators;