import React from 'react';
import Top from "components/Top";
import News from "components/News";
import About from "components/About";
import System from "components/System";
import Web from "components/Web";
import Recruit from "components/Recruit";
import RecruitEntry from "components/RecruitEntry";
import Contact from "components/Contact";
import Privacy from "components/Privacy";
import PrivacyPub from "components/PrivacyPub";
import 'css/style.css';
import {
    BrowserRouter as Router,
    Route,
    Routes,
//    useParams,
//    useHistory,
//    useLocation,
} from 'react-router-dom';
  
const Main: React.FC = () => {
    return (
        <main className="top">
            <Router>
                <Routes>
                    <Route path="/" element={<Top />} />
                    <Route path="/index.html" element={<Top />} />
                    <Route path="/news"element={<News />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/system" element={<System />} />
                    <Route path="/web" element={<Web />} />
                    {/* <Route path="/recruit" element={<Recruit />} /> */}
                    {/* <Route path="/recruit-form" element={<RecruitEntry />} /> */}
                    <Route path="/contact-form" element={<Contact />} />
                    <Route path="/privacypolicy" element={<Privacy />} />
                    <Route path="/privacypolicy-public" element={<PrivacyPub />} />
                </Routes>
            </Router>
        </main>
    );
}

export default Main;