import React, { useEffect } from 'react';
import type { FormType } from "modules/form";
import "css/form.css";

type PropsType = {
    name: string;
    type?: string;
    required?: boolean;
    maxLength?: number;
    placeholder?: string;
    form: FormType;
    disabled?: boolean;
};

export const Text: React.FC<PropsType> = (props) => {
    useEffect(() => {
        props.form.require(props.name, props.required ? "入力されていません。" : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.required]);
    
    const message = props.form.getMessage(props.name);
    return (
        <div className="form-input" style={message === null ? {} : {backgroundColor: '#FFCCCC'}}>
            <input
                name={props.name}
                type={props.type === undefined ? "text" : props.type}
                placeholder={props.placeholder}
                value={props.form.values[props.name]}
                maxLength={props.maxLength}
                onChange={props.form.onChange}
            />
            {message ? <p className="form-input-message">{message}</p> : <></>}
        </div>
    );
}

export default Text;