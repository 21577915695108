import React from 'react';
import "css/form.css";

type PropsType = {
    label: string;
    disabled?: boolean;
    onClick: () => void;
};

export const Button: React.FC<PropsType> = (props) => {
    return (
        <div className="button-wrapper">
        {props.disabled ?
        <button className="disabled-button" disabled>{props.label}</button>
        :
        <button className="enabled-button" onClick={props.onClick}>{props.label}</button>
        }</div>
    );
}
