import React, { CSSProperties } from "react";

type PropsType = {
    id: number;
    index: number;
    message: string;
    interval: number;
    error: boolean;
    width: number;
    height: number;
};

class Toast {
    constructor(props: PropsType) {
        const { id, index, message, interval, error, width, height } = props;
        this.id = id;
        this.index = index;
        this.message = message;
        this.interval = interval;
        this.error = error;
        this.width = width;
        this.height = height;
        this.opened = false;
        this.closing = false;
        this.closed = false;
        this.timer = null;
    }

    id;
    message;
    error;
    width;
    height;
    index;
    interval;
    opened;
    closing;
    closed;
    timer: any;

    setTimer(interval: number, onTimer: (id: number) => void) {
        this.timer = setInterval(() => {
            onTimer(this.id);
        }, interval);
    }

    getWrapperStyle() {
        const style = {
            bottom: `${this.height * this.index + 4}px`,
            display: this.closed ? "none" : "block",
        } as CSSProperties;

        if(this.closed) {
            style['display'] = "none";
        } else if(this.closing) {
            style['animation'] = "toast-out 0.8s forwards";
        } else if(this.opened) {
            style['animation'] = `toast ${this.interval / 1000}s forwards`;
        } else {
            style['animation'] = "toast-in 0.6s forwards";
        }
        return style;
    }

    getOuterStyle() {
        return {
            backgroundColor: this.error ? 'rgb(255, 223, 223)' : 'rgb(223, 255, 223)',
        } as React.CSSProperties
    }

    getAccentStyle() {
        return {
            backgroundColor: this.error ? 'rgb(255, 63, 63)' : 'rgb(63, 255, 63)',
        } as React.CSSProperties
    }

    onIndex(index: number) {
        this.index = index;
    }

    onClose() {
        if(this.timer) {
            clearInterval(this.timer);
        }
        this.closing = true;
    }
}

export default Toast;