import React, { useEffect } from 'react';
import { CodeType } from 'modules/codes';
import type { FormType, ChangeEventType } from "modules/form";
import "css/form.css";

type PropsType = {
    name: string;
    required?: string;
    form: FormType;
    codes: Array<CodeType>;
};

export const Checkbox: React.FC<PropsType> = (props) => {
    useEffect(() => {
        props.form.require(props.name, props.required ? "選択されていません。" : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.required]);
    
    const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const newValues = [];
        for(let value of props.form.values[props.name]) {
            if(value !== event.target.value) {
                newValues.push(value)
            }
        }
        if(!props.form.values[props.name].includes(event.target.value)) {
            newValues.push(event.target.value)
        }
        props.form.onChange({
            target: {
                name: props.name,
                value: newValues,
            }
        } as ChangeEventType)
    }

    const values: Array<string> = props.form.values[props.name] === null ? "" : props.form.values[props.name];

    return (
        <div className="checkbox-wrapper">
            {props.codes.map((code, index) => (<>
                <input name={props.name} type="checkbox" id={props.name + index} value={`${code.code}`} onChange={onChange} checked={values.includes(`${code.code}`)} />
                <label className="checkbox" htmlFor={props.name + index}>{code.name}</label>
            </>))}
        </div>
    );
}
