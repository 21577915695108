import React from 'react';
import Slider from "react-slick";
import top_1_jpg from 'images/top_1.jpg';
import top_2_jpg from 'images/top_2.jpg';
import top_3_jpg from 'images/top_3.jpg';
import 'css/style.css';
import 'css/skeleton.css';
import 'css/slick-theme.css';
import 'css/slick.css';
import $ from 'jquery';
import classNames from 'classnames'

const Top: React.FC = () => {
    
    const onSwipe = (oldIndex: number, newIndex: number) => {
        var $daimoku = $('#daimoku');
        var $rinen = $('#rinen');
        // スライドしたときの文言変更
        switch (newIndex) {
            case 0:
                $($daimoku).hide().fadeIn(1000);
                $($rinen).hide().fadeIn(1000);

                $($daimoku).html("すべては<br>お客様の利益のために")
                break;
            case 1:

                $($daimoku).hide().fadeIn(1000);
                $($rinen).hide().fadeIn(1000);

                $($daimoku).html("人とのつながりを大切に")
                break;
            case 2:
                $($daimoku).hide().fadeIn(1000);
                $($rinen).hide().fadeIn(1000);

                $($daimoku).html("誇りを持つ")
                break;
            default:
                break;
        }
    }

    return (
        <>
        <section className="main-visual">
            <ul className="slick-items">
            <Slider dots={true} dotsClass={"my-dots"} autoplay={true} infinite={true} beforeChange={onSwipe}>
                <li className="heroimage_1">
                    <img src={top_1_jpg} alt="" className={classNames("heroimage", "one")} style={{textAlign: 'right'}} />
                </li>
                <li className="heroimage_1">
                    <img src={top_2_jpg} alt="" className={classNames("heroimage", "two")} />
                </li>
                <li className="heroimage_1">
                    <img src={top_3_jpg} alt="" className={classNames("heroimage", "daruma")} />
                </li>
            </Slider>
            </ul>
            <div className="title">
                <div className="rinen">
                    すべては
                    <br />お客様の利益のために
                </div>
            </div>
            <div className="company-description">
                株式会社ローチェは、情報システム開発及び
                <br />インターネットアプリケーションサービスの提供を行う会社です。
            </div>
            <div className="scroll">
                <a href="#about" className="jump">
                    <span></span>Scroll Down
                </a>
            </div>
            <div className="whats-new">
                {/* <?php echo do_shortcode('[showwhatsnew]'); ?> */}
            </div>
        </section>

        {/* about */}
        <section className="row" id="about">
            <div className="twelve column">
                <div className="about">
                    <div className="contents">
                        <h2 className="index-about border-bottom">
                            ABOUT
                        </h2>
                        <p className="contents_title_jp">会社概要</p>
                        <div className="content-desc">
                            <div className="desc-text">
                                ローチェでは創業以来、一貫してお客様の利益を最大化できるよう、 ひたむきにサービスを磨き続けてまいりました。
                                <br /> 我々が向き合うのは、「企業」というハコではなく、 結局のところ生身の「個人」です。
                                <br />
                                <br /> 「目の前のお客様にとって利益になるか？」
                                <br />「相手の立場で発想できているか？」
                                <br />
                                <br />常にそのような問題意識を持って、日々の業務に取り組んでおります。
                            </div>
                            <div className="btn">
                                <a href="/about" /* style=" text-decoration: none; color:black" */ >
                                    詳しくはこちら
                                    {/* 矢印用 */}
                                    <p></p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* twelve column */}
            </div>
            {/* row */}
        </section>

        {/* system */}
        <section className="row row-eq-height system-row">
            <div className="six columns index">
                <div className="system">
                    <div className="system_content_wrap">
                        <div className="contents">
                            <h2 className="index-system border-bottom">
                                SYSTEM
                            </h2>
                            <p className="contents_title_jp">システム開発</p>
                            <div className="content-desc">
                                <div className="desc-text">
                                    株式会社ローチェではお客様の利益を第一の目的とし、
                                    <br /> お客様の要件をお客様が使いやすいシステムに具現化するシステムインテグレーションを総合的に提供いたします。
                                    <br /> また、弊社システム開発エンジニアがシステム開発プロジェクトをサポートするＳＥサービスも行っております。
                                </div>
                                <div className="btn">
                                    <a href="/system" /* style=" text-decoration: none; color:black" */ >
                                        詳しくはこちら
                                        {/* 矢印用 */}
                                        <p></p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="six columns index">
                <div className="system">
                    <div className="web_content_wrap">
                        <div className="contents text-color-white">
                            <h2 className="index-web border-bottom">
                                WEB
                            </h2>
                            <p className="contents_title_jp">ウェブ開発</p>
                            <div className="content-desc">
                                <div className="desc-text">
                                    当社のWebに特化した技術者が、お客様の要望や業務内容を分析し、最適なWebシステムをご提案させていただきます。
                                    <br />近年多発している不正アクセスに関しても十分な知識で、 お客様に安心して使用していただけるWebシステムをご提案いたします。また、お客様のシステムに最適なサーバー構築並びにインフラ構築及び運用もご提案させていただきます。
                                </div>
                                <div className="btn">
                                    <a href="/web" /* style=" text-decoration: none; color:black" */ >
                                        詳しくはこちら
                                        {/* 矢印用 */}
                                        <p></p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* recruit */}
        <section className="row">
            <div className="twelve column">
                <div className="recruit">
                    <div className="contents text-color-white">
                        <h2 className="index-recruit border-bottom">
                            RECRUIT
                        </h2>
                        <p className="contents_title_jp">採用について</p>
                        <div className="content-desc">
                            <div className="desc-text">
                                自分の価値を高めようとする意欲のある人を全面的に支援していきます。
                                <br />その結果として、高水準の技術を駆使して品質の良いシステムをお客様に提供し、 利益と満足をもたらせることができると考えております。
                                <br />また、社員ひとりひとりの個性を大切にし、誰もが誇りをもっていきいきと働くことができる会社であるために、 常に社内環境の向上と社内コミュニケーションの活性化に努めています。
                                <br />現状に満足せず、常に成長を目指す意識を忘れない方、ローチェの経営理念に共感できる方、一緒に成長していきましょう。
                            </div>
                            <div className="btn">
                                <a href="https://recruit.roce.co.jp" /* style=" text-decoration: none; color:black" */ >
                                    詳しくはこちら
                                    {/* 矢印用 */}
                                    <p></p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* contact */}
        <section className="row">
            <div className="twelve column">
                <div className="contact">
                    <div className="contents text-color-white">
                        <h2 className="index-contact border-bottom">
                            CONTACT
                        </h2>
                        <p className="contents_title_jp">お問い合わせ</p>
                        <div className="content-desc">
                            <div className="desc-text">
                                ▼弊社へのご相談・料金のお見積りなど、お気軽にお問い合わせ下さい。
                            </div>
                            <div className="btn">
                                <a href="/contact-form" /* style=" text-decoration: none; color:black" */ >
                                    詳しくはこちら
                                    {/* 矢印用 */}
                                    <p></p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* IE対応 */}
        <script>
            objectFitImages("img.heroimage");
        </script>
    </>
    );
}

export default Top;