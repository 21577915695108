import { Store, combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import loadingSlice, { initialState as loadingState } from './stores/loading';

const rootReducer = combineReducers({
    loading: loadingSlice.reducer,
});

const preloadedState = () => {
    return {
        loading: loadingState,
    };
};

export type StoreState = ReturnType<typeof preloadedState>;

export type ReduxStore = Store<StoreState>;

const createStore = () => {
    const middlewareList = [...getDefaultMiddleware()];

    return configureStore({
        reducer: rootReducer,
        middleware: middlewareList,
        preloadedState: preloadedState(),
    });
};

export default createStore;