import React from 'react';
import "css/modal.css";

type PropsType = {
    children: React.ReactNode;
};

export const ModalContent: React.FC<PropsType> = (props) => {
    return (
        <div className="modal-content">
            {props.children}
        </div>
    );
};
