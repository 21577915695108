import React, { useEffect } from 'react';
import { ModalHeader } from "./ModalHeader";
import { ModalContent } from "./ModalContent";
import { ModalFooter } from "./ModalFooter";

import "css/modal.css";

type PropsType = {
    isOpen: boolean;
    onClose: () => void;
    style?: React.CSSProperties;
    children: Array<React.ReactNode>;
};

export const Modal: React.FC<PropsType> = (props) => {
    const onClose = () => {
        props.onClose();
    };

    useEffect(() => {
        if(props.isOpen) {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
        } else {
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";
        }
    }, [props.isOpen]);

    return !props.isOpen ? <></> : <>
        <div className="modal">
            <div className="overlay" onClick={onClose} />
            <div className="modal-wrapper">
                {props.children[1]}
                <ModalHeader onClose={onClose}>
                    {props.children[0]}
                </ModalHeader>
                <ModalContent>
                    {props.children[1]}
                </ModalContent>
                <ModalFooter>
                    {props.children[2]}
                </ModalFooter>
            </div>
        </div>
    </>;
};
