import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Main from 'components/Main';
import { ToastContainer } from "components/toast";
import 'css/header.css';
import 'css/style.css';
import $ from 'jquery';

const App: React.FC = () => {
    console.log("■■ App ■■");
    $('a.sub-menu[href^="#"]').click(function () {
        var speed = 700;
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top - 72;
        $('body,html').animate({ scrollTop: position }, speed, 'swing');
        return false;
    });

    // ブラウザバック対策
    window.onbeforeunload = function() {};
    window.onunload = function() {};
    window.addEventListener("pageshow", function(event) {
        if (event.persisted) {
            // ここにキャッシュ有効時の処理を書く
            $('#menu-icon').attr('src', $('#menu-icon').attr('csrc'));
            $('.nav-sp')[0].className = 'nav-sp close';
        }
    });
    
    $(window).on('load', function () {
        $('.main-wrap').addClass('fadeIn');
        var loading = $('.loading');
        $(loading).addClass("fadeOut");
        
        $('.main-wrap').addClass('fadeIn');
        var loading = $('.loading');
        $(loading).addClass("fadeOut");

        //IE11対応
        var $dotsButtons = $(".my-dots li button");
        for (var i = 0; i < $dotsButtons.length; i++) {
            $($dotsButtons[i]).text("");
        };

        $(".my-dots ul li").on("click", function () {
            // @ts-ignore
            window.location = $(this).find("button").click();
            return false;
        });

        $('.index').fadeIn(1000);
        $('.header').fadeIn(1000);
        // Smooth scroll
        $('a.jump').click(function () {
            var speed = 500;
            var href = $(this).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top - $('.header').height();
            $('body,html').animate({ scrollTop: position }, speed, 'swing');
            return false;
        });
    });
    
    $(document).ready(function () {
        // mouseover/out切り替え
        $('.ov').on('mouseover', function () {
            $(this).attr('src', $(this).attr('hsrc'))
        })
        $('.ov').on('mouseout', function () {
            $(this).attr('src', $(this).attr('osrc'))
        })
    });

    return (
        <div className="page-container">
            <ToastContainer width={280} height={80} padding={4} inetrval={4000}>
                <Header />
                <Main />
                <Footer />
            </ToastContainer>
        </div>
    );
}

export default App;
