import React from 'react';
import "css/form.css";

type PropsType = {
    allocation: Array<number>;
    children: React.ReactNode;
};

export const InputRow: React.FC<PropsType> = (props) => {
    let elements: Array<React.ReactNode> = null;
    if(props.children instanceof Array) {
        elements = props.children;
    } else {
        elements = [props.children];
    }
    const gridTemplateColumns = props.allocation.join('fr ') + 'fr';
    const wrapStyle = {
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: gridTemplateColumns,
    };

    return (
        <div style={wrapStyle}>
            {elements.map(element => 
                <>{element}</>
            )}
        </div>
    );
}
