import axios from "axios";

const headerClient = {
    'x-client-id': process.env.REACT_APP_SITE_CLIENT_ID,
    'x-client-secret': process.env.REACT_APP_SITE_CLIENT_SECRET,
};

const headerJson = {
    'content-type': "application/json",
};

const headerMultipart = {
    'content-type': "multipart/form-data",
};

const useSiteApi = () => {
    const createParams = {
        baseURL: process.env.REACT_APP_SONARE_API_URL,
        headers: headerClient,
    };

    const axiosInstance = axios.create(createParams);

    axiosInstance.interceptors.response.use(
        result => {
            console.log(result);
            return result;
        },
        error => {
            console.log(error);
            if (error.response) {
                console.log("通信エラーが発生しました。");
            }
            return Promise.reject(error);
        }
    );

    
    const header = {}
    header['content-type'] = "application/json";
    header['x-client-id'] = process.env.REACT_APP_SONARE_CLIENT_ID;
    header['x-client-secret'] = process.env.REACT_APP_SONARE_CLIENT_SECRET;


    return {
        news: (): Promise<any> => {
            return axiosInstance.get("/announcement-list/corporate", {headers: header});
        },
        contact: (values: any): Promise<any> => {
            const attributes = [values]

            console.log(attributes);
            return axiosInstance.post(
                "/mailbox/site/inquiry",{attributes}, { headers: header }
            );
        },
        recruitEntry: (values: any): Promise<any> => {
            const params = new FormData();
            for(let key in values) {
                const value = values[key];
                if(value) {
                    params.append(key, value);
                }
            }
            return axiosInstance.post(
                "/recruit-entry", params, { headers: headerMultipart }
            );
        },
    };
};

export default useSiteApi;