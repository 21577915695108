import React from 'react';
import 'css/style.css';
import ttl_system_png from "images/ttl_system.png";
import sstm_ttl00_png from "images/sstm_ttl00.png";
import sstm_ttl_ico01_png from "images/sstm_ttl_ico01.png";
import sstm_ttl_ico02_png from "images/sstm_ttl_ico02.png";
import sstm_ttl_ico03_png from "images/sstm_ttl_ico03.png";
import sstm_ttl_ico04_png from "images/sstm_ttl_ico04.png";
import sstm_ttl_ico05_png from "images/sstm_ttl_ico05.png";
import sstm_ttl_ico06_png from "images/sstm_ttl_ico06.png";

const System: React.FC = () => {
    return (
        <>
            <section className="system-main-visual">
                <div className="page-title-wrap">
                    <div className="page-title">
                        SYSTEM
                    </div>
                    <div className="page-sub-text">
                        システム開発
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row main-title">
                    <div className="twelve columns ">
                        <img src={ttl_system_png} width="218" height="30" alt="システム開発" />
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns ">
                        <p id="mainLead" className="block-line">
                            株式会社ローチェではお客様の利益を第一の目的とし、お客様の要件をお客様が使いやすいシステムに具現化するシステムインテグレーションを総合的に提供いたします。 また、弊社システム開発エンジニアがシステム開発プロジェクトをサポートするＳＥサービスも行っております。
                        </p>
                    </div>
                </div>
                {/* system integration */}
                <div className="row" id="rinen_dtls">
                    <div className="twelve columns ">
                        <div className="block block-line" id="block00">
                            <table className="systemLists u-full-width">
                                <thead>
                                    <tr>
                                        <th>業種</th>
                                        <th>環境</th>
                                        <th>言語</th>
                                        <th>DB</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>通信業</td>
                                        <td>Linux</td>
                                        <td>Java</td>
                                        <td>Oracle</td>
                                    </tr>
                                    <tr>
                                        <td>携帯キャリア</td>
                                        <td>Solaris</td>
                                        <td>C#(.net)</td>
                                        <td>DB2</td>
                                    </tr>
                                    <tr>
                                        <td>証券</td>
                                        <td>AIX</td>
                                        <td>VB(.net)</td>
                                        <td>SQLServer</td>
                                    </tr>
                                    <tr>
                                        <td>銀行</td>
                                        <td>Windows/Windows Server</td>
                                        <td>perl</td>
                                        <td>SymfoWear</td>
                                    </tr>
                                    <tr>
                                        <td>生命保険</td>
                                        <td>汎用機</td>
                                        <td>HTML</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>電力会社</td>
                                        <td>VMWare</td>
                                        <td>PRO*C</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>流通業</td>
                                        <td>&nbsp;</td>
                                        <td>
                                            <p>C</p>
                                            <p>COBOL</p>
                                            <p>PHP</p>
                                            <p>SQL</p>
                                            <p>Shell</p>
                                        </td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* / #block00 .block */}
                            {/* </div> */}
                        </div>
                    </div>
                    {/* SYSTEM INTEGRATION */}
                    <div className="block block-line systemTable">
                        <div className="row">
                            <div className="twelve columns ">
                                <h3 className="subTitle">
                                    <img src={sstm_ttl00_png} width="252" height="30" alt="SYSTEM INTEGRATION  （システムインテグレーション）" />
                                </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="three columns">
                                <h4>
                                    <img src={sstm_ttl_ico01_png} width="122" height="108" alt="企画・提案" />
                                </h4>
                            </div>
                            <div className="three columns">
                                <p>お客様の業務内容を的確に把握した上でコスト削減、 効率化を見込めるシステムの提案を行います。</p>
                            </div>
                            <div className="three columns">
                                <h4>
                                    <img src={sstm_ttl_ico02_png} width="122" height="108" alt="要件定義" />
                                </h4>
                            </div>
                            <div className="three columns">
                                <p>システムコンサルテーションにて 提案させていただいたシステムの要件をお客様と具体的に詰めていきます。</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="three columns">
                                <h4>
                                    <img src={sstm_ttl_ico03_png} width="122" height="108" alt="システム設計" />
                                </h4>
                            </div>
                            <div className="three columns">
                                <p>具体的な要件を元に、最適化されたシステム設計を行います。 できうる限りシステムを単純化することによりメンテナンス性の向上と工数の削減を実現します。 </p>
                            </div>
                            <div className="three columns">
                                <h4>
                                    <img src={sstm_ttl_ico04_png} width="122" height="108" alt="開発" />
                                </h4>
                            </div>
                            <div className="three columns">
                                <p>システム設計に忠実なコーディングとテストを行います。 単純化された設計により、バグの発生しにくいプログラムが作成されます。</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="three columns">
                                <h4>
                                    <img src={sstm_ttl_ico05_png} width="122" height="108" alt="企画・提案" />
                                </h4>
                            </div>
                            <div className="three columns">
                                <p>最適化されたシステム設計によりテストの内容は明確となり、確実なテストを実施いたします。</p>
                            </div>
                            <div className="three columns">
                                <h4>
                                    <img src={sstm_ttl_ico06_png} width="122" height="108" alt="導入及び運用" />
                                </h4>
                            </div>
                            <div className="three columns">
                                <p>導入作業から日々の運用作業、さらには機能追加・修正等、お客様のご要望にあわせて柔軟に対応いたします。</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="twelve columns ">
                                <p className="note">※ ［3 システム設計］お客様が扱う情報を中心とした設計をいたします。 （お客様が扱うデータが中心となり、 作成するプログラムはデータのためのものという位置付けです）
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* 開発事例 */}
                    <div className="block-line systemTable">
                        <div className="row">
                            <div className="twelve columns">
                                <h3 className="subTitle">開発事例</h3>
                            </div>
                        </div>
                        {/* 1行目 */}
                        <div className="row">
                            <div className="six columns">
                                <dl>
                                    <dt>保険代理店システム提供会社様向け
                                        <br /> Webアプリケーション基盤開発支援
                                    </dt>
                                    <dd>最適化した設計によりWebアプリケーションのミドルウェア部分の再構築を支援しました。</dd>
                                </dl>
                            </div>
                            <div className="six columns">
                                <dl>
                                    <dt>通信会社向け請求・収納管理システム
                                        <br /> （Webアプリケーション）開発支援
                                    </dt>
                                    <dd>請求書の作成や収納状況を管理する社内システムの開発を支援しました。
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        {/* 2行目 */}
                        <div className="row">
                            <div className="six columns">
                                <dl>
                                    <dt>携帯キャリア向け故障受付システム
                                        <br /> （Webアプリケーション）開発支援
                                    </dt>
                                    <dd>携帯の故障や代替機種貸与・修理に関わる料金の管理を行う社内及び代理店向けシステムの開発を支援しました。</dd>
                                </dl>
                            </div>
                            <div className="six columns">
                                <dl>
                                    <dt>某証券会社向けポジション
                                        <br /> 管理システム開発支援
                                    </dt>
                                    <dd>日々行われる株・債権のトレード情報からポジションや損益の管理を行い、玉繰りや資金繰りに必要な情報を提供するシステムの開発支援を行いました。</dd>
                                </dl>
                            </div>
                        </div>
                        {/* 3行目 */}
                        <div className="row">
                            <div className="six columns">
                                <dl>
                                    <dt>証券取引所向け
                                        <br /> 基幹システム開発支援
                                    </dt>
                                    <dd>証券取引所の基幹システムを最新の技術を駆使して再構築する事業の支援を行っています。
                                    </dd>
                                </dl>
                            </div>
                            <div className="six columns">
                                <dl>
                                    <dt>ASP向け
                                        <br /> Webアプリケーション開発
                                    </dt>
                                    <dd>ASPを新規で企画し、サービス提供に向けて開発中です。</dd>
                                </dl>
                            </div>
                        </div>
                        <div className="row">
                            <div className="twelve columns">
                                <p className="note">また、弊社システム開発エンジニアがシステム開発プロジェクトをサポートするＳＥサービスも行っております。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default System;