import React, { useState } from 'react';
import Modal from "components/modal";
import useSiteApi from "modules/siteApi";
import 'css/style.css';

type NewsType = {
    siteNewsId: number;
    publishTime: Date | null;
    announcementName: string;
    content: string;
}

const News: React.FC = () => {
    const [news, setNews] = useState(null);
    const [newsItem, setNewsItem] = useState(null);
    const siteApi = useSiteApi();

    if(news == null) {
        siteApi.news()
        .then(result => {
            console.log(result)
            setNews(result.data.announcements);
        });
    }

    const isOpen = newsItem !== null;

    const onNews = (idx: number): void => {
        setNewsItem(news[idx]);
    }

    const onClose = () => {
        setNewsItem(null);
    }

    return (
        <>
            <section className="news-main-visual">
                <div className="page-title-wrap">
                    <div className="page-title">
                        News
                    </div>
                    <div className="page-sub-text">
                        お知らせ
                    </div>
                </div>
            </section>
            <section className="container">
                {/* columns should be the immediate child of a .row */}
                <div className="row block-line">
                    <div className="twelve columns article-main-text">
                        <div className="whatsnew">
                        {newsItem === null ? (<>
                            <h3>お知らせ</h3>
                            <hr />
                            {news === null ? <>
                                Loading...
                            </> : (news.length === 0 ? <p>
                                現在お知らせはございません。
                            </p> : news.map((item: NewsType, idx: number) => (<>
                                <div className="news-dtl">
                                    <div className={"linkbtn"} onClick={() => onNews(idx)}>
                                        <span className="news-date">{new Date(item.publishTime).toLocaleDateString()}</span>
                                        <span className="news-title">{item.announcementName}</span>
                                    </div>
                                </div>
                                <hr />
                            </>)))}
                            </>) : <>
                                <h3>{newsItem.title}</h3>
                                <hr />
                                <p className={"news-content"}>{newsItem.content}</p>
                                <div>
                                    <div className={"linkbtn"} onClick={onClose}>
                                        一覧に戻る
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default News;