import React from 'react';
import 'css/style.css';
import $ from 'jquery';
import ttl_privacypolicy_png from "images/ttl_privacypolicy.png";

const Privacy: React.FC = () => {
    return (
        <>
            <section className="privacy-main-visual">
                <div className="page-title-wrap">
                    <div className="page-title">
                        PRIVACY POLICY
                    </div>
                    <div className="page-sub-text">
                        プライバシーポリシー
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row main-title block-line">
                    <div className="twelve columns ">
                        <article>
                            <div id="main">

                                <div id="topArea">
                                    <h2 id="mainTitle">
                                        <img src={ttl_privacypolicy_png} width="342" height="30" alt="プライバシーポリシー" />
                                    </h2>
                                    <h3 className="contents-h3">個人情報保護方針</h3>
                                    <p id="mainLead">株式会社ローチェ（以下、「当社」という）は、ソフトウェア開発、Web制作およびネットワークを通じて情報システムを提供する中で、多くの個人情報を取り扱っています。そこで当社では、この個人情報保護方針に基づき、個人情報保護マネジメントシステムを確立するとともに実行し、事業活動にかかわるすべての従業員が一体となって、個人情報の適切な取り扱いを実現いたします。
                                    </p>
                                    {/* / #topArea */}
                                </div>

                                <div className="block" id="block00">

                                    <h3 className="contents-h3">１．個人情報の適切な取得、利用、提供に関して</h3>
                                    <p>当社では、事業の内容および規模を考慮し、業務遂行に必要な範囲で個人情報を適切な方法で取得します。個人情報の利用は、特定した利用目的の範囲内とし、利用目的を変更する際には、あらかじめご本人に通知し、同意を得た上でおこないます。個人情報は、利用目的を遂行するために業務を委託する場合を除き、ご本人の同意なく、第三者に提供することはございません。目的外の利用や提供を防止するため、個人情報の利用および提供は、個人情報保護管理者が、その適正性を確認した上でおこなっています。また、自主点検、内部監査等の活動を通じた統制活動も強化しております。
                                    </p>

                                    <h3 className="contents-h3">２．個人情報保護のための安全管理策</h3>
                                    <p>（１）　個人情報の漏えい、滅失、き損等に対する予防処置
                                        <br /> 当社では、個人情報の漏洩、滅失、き損等を防止するための適切なセキュリティ対策を実施しております。また、これらを徹底するため、従業員への教育・監督をおこなっています。個人情報取り扱い業務を社外に委託する場合は、委託先の選定や監督を厳重におこなっております。
                                    </p>
                                    <p>（２）　個人情報の漏えい、滅失、き損等に対する是正処置
                                        <br /> 個人情報の漏えい、滅失、き損等が発生した場合は、被害拡大防止を最優先とし、速やかに是正処置を講じるとともに、原因究明をおこない、再発防止に努めます。また、事故に関する情報について、必要な範囲で関係者、関係機関に報告します。
                                    </p>

                                    <h3 className="contents-h3">３．法令、国が定める指針、その他の規範の遵守</h3>
                                    <p>当社は、個人情報の取り扱いに関する法律および関連法令、主務官庁の指針、所属団体等の規範等を遵守します。
                                    </p>

                                    <h3 className="contents-h3">４．個人情報保護マネジメントシステムの継続的改善</h3>
                                    <p>当社は、個人情報の保護に関するマネジメントシステムを実行し、維持するとともに定期的に見直しをおこない、継続的改善に努めます。
                                    </p>

                                    <h3 className="contents-h3">５．個人情報に関する苦情、相談への対応</h3>
                                    <p>当社に対して、個人情報の取り扱いに関する苦情、相談をおこなう場合は、下記の「個人情報お問い合わせ窓口」までご連絡ください。また、当社の個人情報保護に関するお問い合わせについても下記にて対応いたします。</p>

                                    {/* / #block00 .block */}
                                </div>

                                <div className="block" id="block01">
                                    <div id="contactInfo">
                                        <p>【個人情報お問い合わせ窓口】
                                            <br /> 電話：03-6435-8200
                                            <br /> （平日10：00～12：00、13：00～17：00）
                                            <br /> メール：roce@roce.co.jp
                                        </p>
                                        {/* / #contactInfo */}
                                    </div>

                                    <div className="credit">
                                        <p>株式会社ローチェ
                                            <br /> 代表取締役　岩﨑　洋一
                                            <br /> 制定日　2012年11月1日
                                        </p>
                                    </div>

                                    <div className="toPublic">
                                        <p>
                                            <a href="/privacypolicy-public">※個人情報に関する公表事項について</a>
                                        </p>
                                    </div>

                                    {/* / #block01 .block */}
                                </div>

                                {/* / #main */}
                            </div>
                        </article>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Privacy;