import React, { useEffect } from 'react';
// @ts-ignore
import type { FormType } from "modules/form";
import "css/form.css";

type PropsType = {
    name: string;
    rows? : number;
    cols? : number;
    form: FormType;
    required? : boolean;
    disabled?: boolean;
};

export const TextArea: React.FC<PropsType> = (props) => {
    useEffect(() => {
        props.form.require(props.name, props.required ? "入力されていません。" : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.required]);
    
    const message = props.form.getMessage(props.name);
    const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        // @ts-ignore
        props.form.onChange(event);
    };
    return (
        <div className="form-input" style={message === null ? {} : {backgroundColor: '#FFCCCC'}}>
            <textarea
                name={props.name}
                onChange={onChange}
                value={props.form.values[props.name]} 
            />
            {props.form.errors[props.name] ? <p className="form-input-message">{props.form.errors[props.name]}</p> : <></>}
        </div>
    );
}
