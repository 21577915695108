import React, { useState, useEffect, useRef } from 'react';
import "css/modal.css";

type PropsType = {
    onClose: () => void;
    children: React.ReactNode;
};

export const ModalHeader: React.FC<PropsType> = (props) => {
    const [height, setHeight] = useState(0);

    const ref = useRef(null);

    useEffect(() => {
        if (ref?.current) {
            const { height} = ref.current.getBoundingClientRect();
            setHeight(height);
        }
    }, [ref]);

    return (
        <div className="modal-header" style={{marginTop: `-${height}px`}} ref={ref}>
            <div className="modal-close" onClick={props.onClose}>X</div>
            {props.children}
        </div>
    );
};
