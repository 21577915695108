import React from 'react';
import "css/form.css";

type PropsType = {
    label: string;
    required?: boolean;
    children: React.ReactNode;
};

export const Item: React.FC<PropsType> = (props) => {
    return (
        <div className="form-item">
            <div className="form-label">
                {
                    props.required ?
                    <div>
                        <span>{props.label}</span><br />
                        <span className="required">※必須</span>
                    </div>
                    :
                    <span>
                        {props.label}
                    </span>
                }
            </div>
            <div>
                {props.children}
            </div>
        </div>
    );
}
