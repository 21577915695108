import React from 'react';
import 'css/style.css';
import $ from 'jquery';
import cpny_nav_phsy_ov_png from "images/cpny_nav_phsy_ov.png";
import cpny_nav_grt_ov_png from "images/cpny_nav_grt_ov.png";
import cpny_nav_olne_ov_png from "images/cpny_nav_olne_ov.png";
import cpny_nav_hsty_ov_png from "images/cpny_nav_hsty_ov.png";
import ttl_company_philosophy_png from "images/ttl_company_philosophy.png";
import cpny_phsy_vsl_main_png from "images/cpny_phsy_vsl_main.png";
import ttl_company_ceo_png from "images/ttl_company_ceo.png";
import cpny_grt_vsl_main_jpg from "images/cpny_grt_vsl_main.jpg";
import ttl_company_data_png from "images/ttl_company_data.png";
import privacy_mark_png from "images/privacy_mark.png";
import ttl_company_history_png from "images/ttl_company_history.png";

const About: React.FC = () => {

    // news scroller
    $(document).ready(function () {

        $('a.sub-menu[href^="#"]').click(function () {
            var speed = 700;
            var href = $(this).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top - 72;
            $('body,html').animate({ scrollTop: position }, speed, 'swing');
            return false;
        });
    });
    
    return (
        <>
            <section className="about-main-visual">
                <div className="page-title-wrap">
                    <div className="page-title">
                        ABOUT
                    </div>
                    <div className="page-sub-text">
                        会社概要
                    </div>
                </div>
            </section>
            <div className="container">
                {/* columns should be the immediate child of a .row */}
                <div className="row about_icon">
                    <div className="three columns">
                        <a href="#rinen_dtls" id="rinen" className="sub-menu">
                            <img src={cpny_nav_phsy_ov_png} />
                        </a>
                    </div>
                    <div className="three columns">
                        <a href="#aisatsu_dtls" id="rinen" className="sub-menu">
                            <img src={cpny_nav_grt_ov_png} />
                        </a>
                    </div>
                    <div className="three columns">
                        <a href="#gaiyou_dtls" id="rinen" className="sub-menu">
                            <img src={cpny_nav_olne_ov_png} />
                        </a>
                    </div>
                    <div className="three columns">
                        <a href="#enkaku_dtls" id="rinen" className="sub-menu">
                            <img src={cpny_nav_hsty_ov_png} />
                        </a>
                    </div>
                </div>
                {/* 企業理念 */}
                <div className="block-line">
                    <div className="row" id="rinen_dtls">
                        <div className="twelve columns mainTitle">
                            <img src={ttl_company_philosophy_png} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="twelve columns">
                            <img src={cpny_phsy_vsl_main_png} className="contents-img" />
                        </div>
                    </div>
                </div>

                {/* 代表挨拶 */}
                <div className="block-line about-greeting">
                    <div className="row " id="aisatsu_dtls">

                        <div className="twelve columns mainTitle">
                            <img src={ttl_company_ceo_png} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="twelve columns ">
                            <img src={cpny_grt_vsl_main_jpg} className="contents-img" />
                            <h3 className="contents-h3">すべてはお客様のために</h3>
                            <p className="lead ">どんなに優れた技術でも、人の役に立たなければ意味を持たないと考えております。ローチェでは創業以来、一貫してお客様の利益を最大化できるよう、ひたむきにサービスを磨き続けてまいりました。我々が向き合うのは、「企業」というハコではなく、結局のところ生身の「個人」です。「目の前のお客様にとって利益になるか？」、「相手の立場で発想できているか？」常にそのような問題意識を持って、日々の業務に取り組んでおります。</p>

                            <h3 className="contents-h3">IT業界のフロンティアを目指し</h3>
                            <p className="lead ">ITのトレンドや技術は日進月歩で変化していきます。そのスピード感にキャッチアップすることは確かに大変ですが、私自身、インターネット黎明期のあの興奮や、趣味だったプログラミングを楽しむ気持ちを今日も大切にしています。常に業界の最先端を走り続け、お客様に価値ある提案をすることが我々の使命です。</p>

                            <h3 className="contents-h3">そしていま、第二創業期へ</h3>
                            <p className="lead ">リーマンショック後には、長引く不況で苦難な道のりが続きました。 また、会社がまだ若かった頃は、社長である私自身が、何でも自分一人の力でやってしまおうと意気込むあまり、組織の成長を阻害してしまっていたことも事実です。近年では、それらの反省を踏まえ、組織を次のステージに飛躍させるための準備を進めて参りました。新事務所への移転、CIリニューアルなどを一気に加速させる2013年10月を第二の創業期と捉え、新たな気持ちでチャレンジしていく所存です。新しく生まれ変わるローチェに、是非ご期待下さい。
                            </p>
                        </div>

                    </div>
                </div>

                {/* 会社概要 */}
                <div className="row " id="gaiyou_dtls">
                    <div className="twelve columns mainTitle ">
                        <img src={ttl_company_data_png} />
                    </div>

                </div>
                <div className="row">
                    <div className="twelve columns ">
                        <ul>
                        <li className="list-item">
                                <span className="left ">商号:</span>
                                <span className="right ">株式会社ローチェ（英文：Roce,Inc.)</span>
                            </li>
                            <li className="list-item">
                                <span className="left ">設立日:</span>
                                <span className="right ">2005年 4月 5日</span>
                            </li>
                            <li className="list-item">
                                <span className="left ">事業内容:</span>
                                <span className="right ">情報システム開発及びインターネットアプリケーションサービス提供</span>
                            </li>
                            <li className="list-item">
                                <span className="left ">本社所在地:</span>
                                <span className="right ">〒105-0021
                                    <br />東京都港区東新橋2-9-2 パラッツォ ジーラソーレ10F</span>
                            </li>
                            <li className="list-item">
                                <span className="left ">電話番号:</span>
                                <span className="right ">03-6435-8200 (FAX:03-6435-8201)</span>
                            </li>
                            <li className="list-item">
                                <span className="left ">資本金:</span>
                                <span className="right ">50,000,000円</span>
                            </li>
                            <li className="list-item">
                                <span className="left ">決算期:</span>
                                <span className="right ">3月31日</span>
                            </li>
                            <li className="list-item">
                                <span>役員</span>
                                <span>代表取締役社長 兼 CEO : 岩﨑 洋一</span>
                            </li>
                            <li className="list-item">
                                <span></span>
                                <span>取締役副社長 兼 CFO : 岩﨑 しの</span>
                            </li>
                        </ul>
                    </div>
                </div>

                {process.env.REACT_APP_PRIVACY_MARK && (
                    <div className="row">
                        <div id="privacyMark">
                            <p>
                                <a href="http://privacymark.jp/" target="_blank">
                                    <img src={privacy_mark_png} alt="" width="74" height="70" />
                                </a>
                            </p>
                            <p className="lead">プライバシーマークを
                                <br />取得しています。</p>
                            {/* / #privacyMark */}
                        </div>
                    </div>
                )}

                <div className="block-line">
                    <div className="row">
                        <div className="block" id="block01">
                            <h3 className="subTitle">アクセス</h3>
                            <p className="lead">JR山手線・東京メトロ銀座線「新橋」駅 徒歩8分
                                <br /> 都営大江戸線「汐留」駅 徒歩4分</p>

                            <div id="gMap">
                                <iframe width="100%" height="188" frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0} src="https://maps.google.co.jp/maps?f=q&amp;source=s_q&amp;hl=ja&amp;geocode=&amp;q=%e6%9d%b1%e4%ba%ac%e9%83%bd%e6%b8%af%e5%8c%ba%e6%9d%b1%e6%96%b0%e6%a9%8b2%2d9%2d2%20%e3%83%91%e3%83%a9%e3%83%83%e3%83%84%e3%82%a9%20%e3%82%b8%e3%83%bc%e3%83%a9%e3%82%bd%e3%83%bc%e3%83%ac10F(%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE%E3%83%AD%E3%83%BC%E3%83%81%E3%82%A7)&amp;aq=&amp;sll=35.66148,139.757595&amp;sspn=0.007845,0.008669&amp;g=35.66148,139.757595&amp;brcurrent=3,0x60188bc19b643111:0x467451c75fc469d4,0&amp;ie=UTF8&amp;t=m&amp;ll=35.66148,139.757595&amp;spn=0.001639,0.005912&amp;z=17&amp;iwloc=A&amp;output=embed"></iframe>
                                <br />
                                <small>
                                    <a href="https://maps.google.co.jp/maps?f=q&amp;source=embed&amp;hl=ja&amp;geocode=&amp;q=35.66148,139.757595(%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE%E3%83%AD%E3%83%BC%E3%83%81%E3%82%A7)&amp;aq=&amp;sll=35.66148,139.757595&amp;sspn=0.007845,0.008669&amp;g=35.66148,139.757595&amp;brcurrent=3,0x60188bc19b643111:0x467451c75fc469d4,0&amp;ie=UTF8&amp;t=m&amp;ll=35.66148,139.757595&amp;spn=0.001639,0.005912&amp;z=17&amp;iwloc=A"
                                    style={{color: '#0000FF', textAlign: 'left'}}>大きな地図で見る</a>
                                </small>
                            </div>

                            <div id="gStreetView">
                                <iframe width="100%" height="290" frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0} src="https://maps.google.co.jp/maps?f=q&amp;source=embed&amp;hl=ja&amp;geocode=&amp;q=35.66148,139.757595(%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE%E3%83%AD%E3%83%BC%E3%83%81%E3%82%A7)&amp;aq=&amp;sll=35.66148,139.757595&amp;sspn=0.007845,0.008669&amp;brcurrent=3,0x60188bc19b643111:0x467451c75fc469d4,0&amp;ie=UTF8&amp;t=m&amp;layer=c&amp;cbll=35.661367,139.757631&amp;panoid=LmeZ620C9PjtbwaU4HFTKA&amp;cbp=13,319.03,,0,-2.48&amp;ll=35.655588,139.757595&amp;spn=0.020225,0.047379&amp;z=14&amp;output=svembed"></iframe>
                                <br />
                                <small>
                                    <a href="https://maps.google.co.jp/maps?f=q&amp;source=embed&amp;hl=ja&amp;geocode=&amp;q=35.66148,139.757595(%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE%E3%83%AD%E3%83%BC%E3%83%81%E3%82%A7)&amp;aq=&amp;sll=35.66148,139.757595&amp;sspn=0.007845,0.008669&amp;brcurrent=3,0x60188bc19b643111:0x467451c75fc469d4,0&amp;ie=UTF8&amp;t=m&amp;layer=c&amp;cbll=35.661367,139.757631&amp;panoid=LmeZ620C9PjtbwaU4HFTKA&amp;cbp=13,319.03,,0,-2.48&amp;ll=35.655588,139.757595&amp;spn=0.020225,0.047379&amp;z=14"
                                    style={{color: '#0000FF', textAlign: 'left'}}>大きな地図で見る</a>
                                </small>
                            </div>
                            {/* / #block01 .block */}
                        </div>
                    </div>
                </div>

                {/* 沿革 */}
                <div className="block-line">
                    <div className="row " id="enkaku_dtls">

                        <div className="twelve columns about_titles ">
                            <img src={ttl_company_history_png} />
                        </div>
                        <div className="twelve columns ">
                            <div className="block " id="block00 ">
                                <ul>
                                    <li className="list-item">
                                        <span className="date ">2019/6/17</span>
                                        <span className="action ">資本金を1000万から5000万に増資</span>
                                    </li>
                                    <li className="list-item">
                                        <span className="date ">2013/9/14</span>
                                        <span className="action ">組織拡大の為、東京都港区へ本社移転</span>
                                    </li>
                                    <li className="list-item">
                                        <span className="date ">2013/3/18</span>
                                        <span className="action ">プライバシーマーク取得（JISQ15001：2006準拠）
                                            <br />登録番号：第17001481（01）号</span>
                                    </li>
                                    <li className="list-item">
                                        <span className="date ">2011/3/9</span>
                                        <span className="action ">特定労働派遣取得　 特13-313728</span>
                                    </li>
                                    <li className="list-item">
                                        <span className="date ">2006/5/24</span>
                                        <span className="action ">商号を株式会社ローチェに変更</span>
                                    </li>
                                    <li className="list-item">
                                        <span className="date ">2006/5/24</span>
                                        <span className="action ">資本金を400万から1000万に増資</span>
                                    </li>
                                    <li className="list-item">
                                        <span className="date ">2006/5/ 1</span>
                                        <span className="action ">本社をツオード千住壱番館から東京芸術センターに移転</span>
                                    </li>
                                    <li className="list-item">
                                        <span className="date ">2005/4/ 5</span>
                                        <span className="action ">有限会社ローチェを設立</span>
                                    </li>
                                </ul>
                                {/* / #block00 .block */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default About;