import { useState } from 'react';
import validators from "modules/validators";

export type FormType = {
    disabled: boolean;
    require: (name: string, requiredMessage: string) => void;
    getMessage: (name: string) => string | null;
    values: any;
    errors: any;
    patch: (patch: any) => void;
    onChange: (event: ChangeEventType) => void;
    onSubmit: () => void;
    reset: () => void;
};

export type ChangeEventType = {
    target: {
        name: string;
        value: string | string[];
    }
}

export type FormValuesType = {[key: string]: null | string | number | File | Array<string>};

export type FormErrorsType = {[key: string]: string};

function useForm(initialValues: FormValuesType, doSubmit: () => void) {
    const [withValidation, setWithValidation] = useState(false);
    const [formRequired] = useState({} as FormErrorsType);
    const [formValues, setFormValues] = useState({ ...initialValues });
    const [formErrors, setFormErrors] = useState({} as FormErrorsType);

    const validate = (values: FormValuesType) => {
        const errors: FormErrorsType = {};

        for (let name in values) {
            let message: string | null = null;
            const requiredMessage = formRequired[name];
            let isEmpty = values[name] === null;
            if(!isEmpty) {
                if(values[name] instanceof Array) {
                    isEmpty = (values[name] as Array<string>).length === 0;
                } else {
                    isEmpty = values[name] === "";
                }
            }
            if(isEmpty && requiredMessage) {
                message = requiredMessage;
            }
            if(!isEmpty && validators[name]) {
                validators[name].forEach(validator => {
                    if(message === null) {
                        message = validator(values[name]);
                    }
                })
                if(message !== null) {
                    errors[name] = message;
                }
            }
            if(message) {
                errors[name] = message;
            }
        }

        return errors;
    }

    return {
        disabled: 0 < Object.keys(formErrors).length,
        values: formValues,
        errors: formErrors,
        require: (name, requiredMessage) => {
            formRequired[name] = requiredMessage;
        },
        getMessage: (name: string): string | null => {
            return formErrors[name] ? formErrors[name] : null;
        },
        patch: (patch: FormValuesType) => {
            const newValues = { ...formValues }
            for (let name in patch) {
                newValues[name] = patch[name];
            }
            if(withValidation) {
                setFormErrors(validate(newValues));
            }
            setFormValues(newValues);
        },
        onChange: (event: ChangeEventType) => {
            const {target: {name, value}} = event;
            const newValues: FormValuesType = {
                ...formValues,
                [name]: value,
            };
            if(withValidation) {
                setFormErrors(validate(newValues));
            }
            setFormValues(newValues);
        },
        onSubmit: () => {
            let errors = null;
            if(withValidation) {
                errors = formErrors;
            } else {
                errors = validate(formValues);
            }
            if(Object.keys(errors).length === 0) {
                doSubmit();
            } else if(!withValidation) {
                setFormErrors(errors);
                setWithValidation(true);
            }
        },
        reset: () => {
            const newValues: FormValuesType = {
                ...initialValues,
            };
            console.log("reset " + initialValues)
            setWithValidation(false);
            setFormErrors({});
            setFormValues(newValues);
        }
    } as FormType;
}

export default useForm;