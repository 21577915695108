import React from 'react';
import 'css/style.css';
import ttl_web_png from "images/ttl_web.png";
import web_img00_png from "images/web_img00.png";
import web_vsl_tel_png from "images/web_vsl_tel.png";

const Web: React.FC = () => {
    return (
        <>
            <section className="web-main-visual">
                <div className="page-title-wrap">
                    <div className="page-title">
                        WEB
                    </div>
                    <div className="page-sub-text">
                        ウェブ開発
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row main-title">
                    <div className="twelve columns ">
                        <img src={ttl_web_png} width="183"
                        height="30" alt="WEB制作" />
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns ">
                        <p id="mainLead" className="block-line">
                            当社のＷｅｂに特化した技術者が、お客様の要望や業務内容を分析し、お客様に最適なWebシステムをご提案させていただきます。近年多発している不正アクセスに関しても十分な知識で、お客様に安心して使用していただけるWebシステムをご提案いたします。また、お客様のシステムに最適なサーバー構築並びにインフラ構築及び運用もご提案させていただきます。
                            また、弊社システム開発エンジニアがシステム開発プロジェクトをサポートするＳＥサービスも行っております。
                        </p>
                    </div>
                </div>
                {/* 開発事例 */}
                <div className="block-line">
                    <div className="row">
                        <div className="twelve columns">
                            <h3 className="subTitle">制作実績</h3>
                        </div>
                    </div>
                    {/* 1行目 */}
                    <div className="row">
                        <div className="twelve columns">
                            <dl>
                                <dt>お客様：サービス業</dt>
                                <dd>人事、給与システムをWebを使い、携帯端末より、勤怠入力等を可能にするシステムの開発を行いました。</dd>
                                <dt>環境</dt>
                                <dd>OS：Linux
                                    <br /> DB：Oracle10ｇ
                                    <br /> フレームワーク：seaser2
                                    <br /> 開発言語：Java
                                </dd>
                            </dl>
                        </div>
                    </div>
                    {/* 2行目 */}
                    <div className="row">
                        <div className="twelve columns">
                            <div className="visual web">
                                <img src={web_img00_png} width="554" height="272" alt="" />
                                {/* / .visual */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* お問い合わせ */}
                <div className="block-line">
                    <div className="row">
                        <div className="twelve columns">
                            <div className="block" id="block01">
                                <h4 className="subTitle">お見積はお気軽にお問い合わせください。</h4>
                                <p className="telephone">
                                    <img src={web_vsl_tel_png} alt="03-6434-8200" width="172" height="16" />
                                </p>
                                <p className="lead">※土・日・祝日、および平日17:00以降のご相談については翌営業日の対応とさせていただきます。
                                    <br /> 予めご了承ください。
                                </p>
                                <div className="btnContact">
                                    <a href="/contact-form">
                                        <button className="inquiry-button">
                                            <i className="far fa-envelope"></i> お問い合わせ</button>
                                    </a>
                                </div>
                                {/* / #block01 .block */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Web;