import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export type LoadingState = {
    loading: boolean;
};

export const initialState: LoadingState = {
    loading: false,
};

const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        apply: () => ({ loading: true }),
        reject: () => ({ loading: false }),
    },
});

export const useLoadingState = () => {
    return useSelector((state: { loading: LoadingState }) => state);
};

export default loadingSlice;