import React from 'react';
import footer_svg from 'svg/footer.svg';
import foot_privacy_mark_ov_png from 'images/foot_privacy_mark_ov.png';
import 'css/footer.css';

const Footer: React.FC = () => {
    return (
        <footer>
            <section className="logos">
                <ul className="footer_logos">
                    <li className="footer_logo_2">
                        <div className="footer_logo">
                            <img src={footer_svg} id="footer-logo" />
                        </div>
                    </li>
                    <li className="footer_logo_2">
                        <div className="company">
                            <span className="span1">株式会社ローチェ
                                <br />
                            </span>
                            <span className="span2">
                                <br />
                            </span>
                            <span className="span3"> 〒105-0021
                                <br />東京都港区東新橋2-9-2 パラッツォ ジーラソーレ10F</span>
                        </div>
                    </li>
                    {process.env.REACT_APP_PRIVACY_MARK && (
                        <li className="footer_logo_2">
                            <img src={foot_privacy_mark_ov_png} />
                        </li>
                    )}
                </ul>
            </section>
            <section className="nav-footer">
                <div className="container">
                    <div className="row">
                        <div className="three columns">
                            <a className="trans" href="/news">お知らせ</a>
                        </div>
                        <div className="three columns">
                            <a className="trans" href="/contact-form">お問い合わせ</a>
                        </div>
                        <div className="three columns">
                            <a className="trans" href="/privacypolicy">プライバシーポリシー</a>
                        </div>
                        <div className="three columns">
                            <a className="trans" href="/sitemap">サイトマップ</a>
                        </div>
                    </div>
                </div>
            </section>
            <div className="copy-right">COPYRIGHT © ROCE, INC. All Rights Reserved.</div>
        </footer>
    );
}

export default Footer;
