import React, { useState, useEffect } from 'react';
import header_svg from "svg/header.svg";
import footer_svg from "svg/footer.svg";
import menu_svg from "svg/menu.svg";
import close_svg from "svg/close.svg";
import 'css/header.css';
import 'css/style.css';
import $ from 'jquery';

type MenuStateType = {
    open: boolean;
    className: string;
    src: string;
};

const menuStateOpen = {
    open: true,
    className: "nav-sp responsive",
    src: menu_svg,
} as MenuStateType;

const menuStateClose = {
    open: false,
    className: "nav-sp close",
    src: close_svg,
} as MenuStateType;

const Header: React.FC = () => {
    const baseStyle = {
        textDecoration: 'none',
        color: 'black'
    };

    const openDrawerMenu = () => {
        var $nav = $('.nav-sp')
        if ($nav[0].className === "nav-sp close"
            || $nav[0].className === "nav-sp") {
            $('#menu-icon').attr('src', close_svg);
            $nav[0].className = "nav-sp responsive";
            // スクロール停止の処理
            window.addEventListener('touchmove', scrollOff, { passive: false });

        } else {
            $('#menu-icon').attr('src', menu_svg);
            $nav[0].className = "nav-sp close";

            // スクロール停止することを停止する処理
            window.removeEventListener('touchmove', scrollOff, {});
        }
    }

    const scrollOff = function(event: any) {
        event.preventDefault();
    }

    return (<>
        {/*  グローバルメニュー */}
        <header className="header sticky" id="header">
            <div className="row">
                <div className="six columns header-logo">
                    {/*  ヘッダーロゴ部分 */}
                    <a href="/" className="header-logo_link" style={baseStyle}>
                        <img src={header_svg} id="header-logo" />
                        <span className="header-logo_name">株式会社ローチェ</span>
                    </a>
                </div>
                <div className="six columns menu-items">
                    <input type="checkbox" id="menu" />
                    {/*  <label for="menu"></label> */}
                    <button id="hamburger-menu" className="menu-btn">
                        <img src={menu_svg} alt="メニュー" id="menu-icon" onClick={openDrawerMenu}/>
                    </button>
                    <nav className="menu">
                        <div style={{height: '100%'}}>
                            <ul id="nav-pc">
                                <li className="menu-en">
                                    <a href="/news" style={baseStyle}>
                                        <div className="about_m">
                                            NEWS
                                        </div>
                                        <div className="about_m menu-jp">
                                            お知らせ
                                        </div>
                                    </a>
                                </li>
                                <li className="border"></li>
                                <li className="menu-en">
                                    <a href="/about" style={baseStyle}>
                                        <div className="about_m">
                                            ABOUT
                                        </div>
                                        <div className="about_m menu-jp">
                                            会社概要
                                        </div>
                                    </a>
                                </li>
                                <li className="border"></li>
                                <li className="menu-en">
                                    <a href="/system" style={baseStyle}>
                                        <div className="system_m">
                                            SYSTEM
                                        </div>
                                        <div className="system_m menu-jp">
                                            システム開発
                                        </div>
                                    </a>
                                </li>
                                <li className="border"></li>
                                <li className="menu-en">
                                    <a href="/web" style={baseStyle}>
                                        <div className="web_m">
                                            WEB
                                        </div>
                                        <div className="web_m menu-jp">
                                            ウェブ開発
                                        </div>
                                    </a>
                                </li>
                                <li className="border"></li>
                                <li className="menu-en">
                                    <a href="https://recruit.roce.co.jp" style={baseStyle}>
                                        <div className="recruit_m">
                                            RECRUIT
                                        </div>
                                        <div className="recruit_m menu-jp">
                                            採用情報
                                        </div>
                                    </a>
                                </li>
                                <li className="border"></li>
                                <li className="menu-en">
                                    <a href="/contact-form" style={baseStyle}>
                                        <div className="contact_m">
                                            CONTACT
                                        </div>
                                        <div className="contact_m menu-jp">
                                            お問い合わせ
                                        </div>

                                    </a>
                                </li>

                            </ul>
                        </div>

                    </nav>
                    <div className="row">
                        <ul className="nav-sp">
                            <li>
                                <a href="/news" style={baseStyle}>
                                    {/*  NEWS */}
                                    <div className="about_m">
                                        NEWS
                                    </div>
                                    <div className="about_m menu-jp">
                                        お知らせ
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/about" style={baseStyle}>
                                    <div className="about_m">
                                        ABOUT
                                    </div>
                                    <div className="about_m menu-jp">
                                        会社概要
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/system" style={baseStyle}>
                                    <div className="about_m">
                                        SYSTEM
                                    </div>
                                    <div className="about_m menu-jp">
                                        システム開発
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/web" style={baseStyle}>
                                    <div className="about_m">
                                        WEB
                                    </div>
                                    <div className="about_m menu-jp">
                                        WEB開発
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://recruit.roce.co.jp" style={baseStyle}>
                                    <div className="about_m">
                                        RECRUIT
                                    </div>
                                    <div className="about_m menu-jp">
                                        採用情報
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/contact-form" style={baseStyle}>
                                    <div className="about_m">
                                        CONTACT
                                    </div>
                                    <div className="about_m menu-jp">
                                        お問い合わせ
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/*  .row */}
                </div>
            </div>
        </header>
    </>);
}

export default Header