import React from 'react';
import 'css/style.css';
import $ from 'jquery';
import ttl_privacypolicy_png from "images/ttl_privacypolicy.png";
import contact_sheet_pdf from "docs/contact_sheet.pdf";

const PrivacyPub: React.FC = () => {
    return (
        <>
            <section className="privacy-main-visual">
                <div className="page-title-wrap">
                    <div className="page-title">
                        PRIVACY POLICY
                    </div>
                    <div className="page-sub-text">
                        プライバシーポリシー
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row main-title block-line">
                    <div className="twelve columns ">
                        <article>
                            <div id="main">

                                <div id="topArea">
                                    <h2 id="mainTitle">
                                        <img src={ttl_privacypolicy_png} width="342" height="30" alt="プライバシーポリシー" />
                                    </h2>
                                    <h3 className="contents-h3">個人情報に関する公表事項</h3>
                                    {/* / #topArea */}
                                </div>

                                <div className="block" id="block00">

                                    <h3 className="contents-h3">１．個人情報の利用目的の公表に関する事項</h3>
                                    <p>株式会社ローチェ（以下、「当社」という）が取り扱う個人情報の利用目的は、下表の通りです。（※）が付された個人情報については、開示対象個人情報として、当社に対して利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止のご請求をおこなうことができます。</p>

                                    <table id="privacyLists">
                                        <tbody>
                                            <tr>
                                                <th scope="col">個人情報の種類</th>
                                                <th scope="col">利用目的</th>
                                            </tr>
                                            <tr>
                                                <td>応募者情報※</td>
                                                <td>弊社が必要とする人材の採用判断のため</td>
                                            </tr>
                                            <tr>
                                                <td>在職者情報※</td>
                                                <td>人事管理業務および業務の連絡のため</td>
                                            </tr>
                                            <tr>
                                                <td>退職者情報※</td>
                                                <td>法で定める退職者の人事管理業務および懇親等の必要に応じた連絡のため</td>
                                            </tr>
                                            <tr>
                                                <td>受託業務にともないお預かりする個人情報</td>
                                                <td>受託業務遂行のため</td>
                                            </tr>
                                            <tr>
                                                <td>パートナー企業様から取得するスキルシート</td>
                                                <td>弊社クライアント先へのご紹介のため</td>
                                            </tr>
                                            <tr>
                                                <td>各種お問い合わせ情報※</td>
                                                <td>各種お問い合わせ対応のため</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h3 className="contents-h3">２．開示対象個人情報に関する周知事項</h3>
                                    <p>当社は、開示対象個人情報の取り扱いにあたり、下記の事項を公表いたします。</p>

                                    <dl>
                                        <dt>（１）当社の名称</dt>
                                        <dd>株式会社ローチェ（東京都港区）</dd>
                                        <dt>（２）個人情報保護管理者</dt>
                                        <dd>笠松 瑞江（03-6435-8200）</dd>
                                        <dt>（３）すべての開示対象個人情報の利用目的</dt>
                                        <dd>「１．個人情報の利用目的の公表に関する事項」参照</dd>
                                        <dt>（４）開示対象個人情報の取り扱いに関するお問い合わせ・苦情等の申し出先</dt>
                                        <dd>
                                            名称：株式会社ローチェ
                                            <br /> 担当：個人情報保護責任者 笠松 瑞江
                                            <br /> 住所：〒105-0021 東京都港区東新橋2-9-2 パラッツォジーラソーレ10F
                                            <br /> 電話：03-6435-8200
                                        </dd>
                                        <dt>（５）当社が属する認定個人情報保護団体の名称および苦情の解決の申し出先</dt>
                                        <dd>
                                            認定個人情報保護団体の名称：一般財団法人日本情報経済社会推進協会
                                            <br /> 苦情の解決の申出先：個人情報保護苦情相談室
                                            <br /> 住所：〒106-0032 東京都港区六本木一丁目９番９号六本木ファーストビル内
                                            <br /> 電話番号：0120-700-779
                                            <br /> 【当社の商品・サービスに関する問合せ先ではございません】
                                        </dd>
                                        <dt>（６）開示等の請求方法</dt>
                                        <dd>「３．個人情報の開示等のご請求に応じる手続き」参照</dd>
                                    </dl>

                                    <h3 className="contents-h3"> ３．個人情報の開示等のご請求に応じる手続き</h3>
                                    <p>当社は個人情報の利用目的の通知、開示、内容の訂正・追加・削除、利用の停止、消去または第三者への提供停止のご請求および個人情報に関する苦情・ご相談については、PMS事務局（電話：03-6435-8200 [受付時間：平日10：00～12：00、13：00～17：00]）にて承ります。ただし、ご請求いただいた個人情報につき、法令等の規定により個人情報を開示できない場合や当社の保管期間を過ぎた場合については、お客様の個人情報の利用目的の通知、開示、内容の訂正・追加・削除、利用の停止、消去、または第三者への提供停止に応じることができませんのでご了承ください。
                                    </p>

                                    <p>個人情報の開示等をご請求される皆さまは、
                                        <a href={contact_sheet_pdf}
                                        download>こちら</a>から所定の様式をダウンロードし、必要事項をご記入の上、下記住所までご送付ください。</p>

                                    <h4 className="privacy-h4">【ご送付いただくもの】</h4>
                                    <dl>
                                        <dt>（１）「個人情報お問い合せ申請書」</dt>
                                        <dd>所定の用紙に以下の事項をご記入ください。
                                            <br /> ①ご本人様の住所・氏名・電話番号とご捺印
                                            <br /> ②請求日
                                            <br /> ③開示をお求めになる個人情報を特定するための情報
                                        </dd>
                                        <dt>（２）ご本人様、代理人の方であることを示す証明書</dt>
                                        <dd>＜ご本人様の場合＞
                                            <br /> ・運転免許証、パスポート、健康保険の被保険者証、在留カードの写しのいずれか１つ
                                            <br /> ＜代理人の方の場合＞
                                            <br /> ・代理人である事を示す委任状（必須）
                                            <br /> ・委任状に押印された本人の印鑑証明書、代理人の本人確認書類のいずれか1つ
                                        </dd>
                                        <dt>（３）手数料：1,000円（利用目的の通知または開示の場合のみ）</dt>
                                    </dl>

                                    <h4 className="noMgn privacy-h4">【ご送付先】</h4>
                                    <p className="noMgn">住所：〒105-0021 東京都港区東新橋2-9-2 パラッツォジーラソーレ10F
                                        <br /> 株式会社ローチェ　個人情報保護管理者
                                    </p>

                                    <p>※代理人の方がご請求される場合は、PMS事務局までお問い合わせください。なお、代理人請求ができる方は下記の方に限らせていただきます。
                                        <br /> ・未成年者または成年被後見人の法定代理人
                                        <br /> ・開示等の求めをすることにつき本人が委任した代理人
                                    </p>

                                    <p>当社では、上記の請求を受領した場合は、必要な調査を実施した上で、2週間以内に書面にて回答致します。回答にあたっては、なりすましによる個人情報の漏えいを防止する目的で、本人限定受取郵便を用いますので、ご了承ください。
                                    </p>
                                    <p className="last">以上</p>
                                    {/* / #block00 .block */}
                                </div>

                                {/* / #main */}
                            </div>
                        </article>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPub;