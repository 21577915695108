import React, { useState, useContext } from 'react';
import 'css/style.css';
import 'css/form.css';
import ttl_contact_png from "images/ttl_contact.png";
import useForm, { FormValuesType } from "modules/form"
import { Item, InputRow, Text, TextArea, Select, Checkbox, AddressInjection, TownType, Button, Submit } from "components/form";
import Modal from "components/modal";
import { prefectureList, agreeList } from "modules/codes";
import useSiteApi from "modules/siteApi";
import { ToastContext, ToastContextType } from "components/toast";

interface ContactFormType extends FormValuesType {
    lastName: string;
    firstName: string;
    lastNameKana: string;
    firstNameKana: string;
    email: string;
    tel1: string;
    zipCd: string;
    prefectureCd: string | number;
    address0: string;
    address1: string;
    address2: string;
    companyName: string;
    divisionName: string;
    contents: string;
    agree: Array<string>;
}

const Contact: React.FC = () => {
    const siteApi = useSiteApi();
    const [open, setOpen] = useState(false);
    
    const toast: ToastContextType = useContext(ToastContext);

    const form = useForm({
        lastName: "",
        firstName: "",
        lastNameKana: "",
        firstNameKana: "",
        email: "",
        tel1: "",
        zipCd: "",
        prefectureCd: "",
        address0: "",
        address1: "",
        address2: "",
        companyName: "",
        divisionName: "",
        contents: "",
        agree: [],
    } as ContactFormType, () => {
        setOpen(true);
    });

    const onAddressInjection = (town: TownType) => {
        form.patch({
            prefectureCd: town.prefectureCd,
            address0: town.localGovernmentName,
            address1: town.town,
        } as ContactFormType);
    }

    const onSubmit = () => {
        siteApi.contact(form.values)
        .then(result => {
            form.reset();
            toast.info("送信しました。");
        })
        .catch(error => {
            toast.error("通信エラーです。");
        })
        setOpen(false);
    }

    const onClose = () => {
        setOpen(false);
    }

    return (
        <>
            <section className="contact-main-visual">
                <div className="page-title-wrap">
                    <div className="page-title">
                        CONTACT
                    </div>
                    <div className="page-sub-text">
                        お問い合わせ
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="row main-title">
                    <div className="twelve columns ">
                        <img src={ttl_contact_png} width="225" height="30" alt="お問い合わせ" />
                    </div>
                </div>

                {/* お問い合わせについて */}
                <div className="block-line">
                    <div className="row">
                        <div className="twelve columns">
                            <div id="main">
                                <div id="topArea">
                                    <p id="mainLead">お客様の個人情報、お寄せいただいた相談内容は厳重に取扱い、プライバシー保護に努めます。お問合せの内容によっては、お返事を差し上げるまでにお時間がかかることがあります。あらかじめご了承下さい。お客様からいただいたeメールアドレスが正しいものでない場合や、システム障害などによりお返事できない場合がございます。返答のない場合はお電話でその旨お問い合わせください。</p>
                                    {/* / #topArea */}
                                </div>
                                <div className="block" id="block00">
                                    <h3 className="subTitle form-title">お問い合わせフォーム</h3>
                                    <Item label={"氏名（漢字）"} required={true}>
                                        <InputRow allocation={[6, 6]}>
                                            <Text
                                                name="lastName"
                                                required={true}
                                                form={form}
                                                placeholder="姓"
                                            />
                                            <Text
                                                name="firstName"
                                                required={true}
                                                form={form}
                                                placeholder="名"
                                            />
                                        </InputRow>
                                    </Item>
                                    <Item label={"氏名（かな）"} required={true}>
                                        <InputRow allocation={[6, 6]}>
                                            <Text
                                                name="lastNameKana"
                                                required={true}
                                                form={form}
                                                placeholder="せい"
                                            />
                                            <Text
                                                name="firstNameKana"
                                                required={true}
                                                form={form}
                                                placeholder="めい"
                                            />
                                        </InputRow>
                                    </Item>
                                    <Item label={"メールアドレス"} required={true}>
                                        <Text
                                            name="email"
                                            required={true}
                                            form={form}
                                            placeholder=""
                                        />
                                    </Item>
                                    <Item label={"電話番号"} required={false}>
                                        <Text
                                            name="tel1"
                                            form={form}
                                            placeholder=""
                                        />
                                    </Item>
                                    {/* <Item label={"住所"} required={false}>
                                        <InputRow allocation={[5, 7]}>
                                            <Text
                                                name="zipCd"
                                                form={form}
                                                maxLength={7}
                                                placeholder="郵便番号"
                                            />
                                            <AddressInjection
                                                zipCd={form.values.zipCd}
                                                label={"検索"}
                                                onChange={onAddressInjection}
                                            />
                                        </InputRow>
                                        <InputRow allocation={[5, 7]}>
                                            <Select
                                                name="prefectureCd"
                                                form={form}
                                                codes={prefectureList}
                                            />
                                            <Text
                                                name="address0"
                                                form={form}
                                                placeholder="市区町村"
                                            />
                                        </InputRow>
                                        <Text
                                            name="address1"
                                            form={form}
                                            placeholder="町名、番地"
                                        />
                                        <Text
                                            name="address2"
                                            form={form}
                                            placeholder="建物名等"
                                        />
                                    </Item> */}
                                    {/* <Item label={"会社名"} required={false}>
                                        <Text
                                            name="companyName"
                                            form={form}
                                            placeholder="会社名"
                                        />
                                    </Item>
                                    <Item label={"所属部署"} required={false}>
                                        <Text
                                            name="divisionName"
                                            form={form}
                                            placeholder="所属部署"
                                        />
                                    </Item> */}
                                    <Item label={"問い合わせ内容"} required={true}>
                                        <TextArea
                                            name="contents"
                                            required={true}
                                            form={form}
                                        />
                                    </Item>
                                    <div>
                                        <Submit form={form} label={"送信"}/>
                                    </div>
                                </div>
                                {/* / #main */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={open} onClose={onClose}>
                <div className="modal-inner">
                    <h4>個人情報に関する同意のお願い</h4>
                </div>
                <div className="modal-inner">
                    <p>お客様の個人情報、お寄せいただいたお問い合わせの内容は厳重に取扱い、プライバシー保護に努めます。</p>
                    <p>お問合せの内容によってはお返事を差し上げるまでにお時間がかかることがありますので、あらかじめご了承下さい。</p>
                    <p>お客様からいただいたeメールアドレス等、入力されたお客様の個人情報が正しいものでない場合や、システム障害などによりお返事できない場合がございます。</p>
                    <div className="button-wrapper">
                        <Checkbox name="agree" codes={agreeList} form={form} />
                    </div>
                </div>
                <div className="modal-button-wrapper">
                    <InputRow allocation={[3,3,3,3]}>
                        <div />
                        <Button label="Cancel" onClick={onClose} ></Button>
                        <Button label="OK" onClick={onSubmit} disabled={form.values['agree'].length === 0}></Button>
                        <div />
                    </InputRow>
                </div>
            </Modal>
        </>
    )
}

export default Contact;